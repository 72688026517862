import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [

  {
    path: "/",
    redirect: 'login'
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },

  {
    path: "/main",
    name: "main",
    component: () => import("../views/main/main.vue"),
    children: [
      {
        path: "/profiles1",
        name: "profiles1",
        component: () => import("../views/data-profiles/profiles1.vue"),
      },
      {
        path: "/profiles2",
        name: "profiles2",
        component: () => import("../views/data-profiles/profiles2.vue"),
      },
      {
        path: "/profiles3",
        name: "profiles3",
        component: () => import("../views/data-profiles/profiles3.vue"),
      },
      {
        path: "/profiles4",
        name: "profiles4",
        component: () => import("../views/data-profiles/profiles4.vue"),
      },
      {
        path: "/profiles5",
        name: "profiles5",
        component: () => import("../views/data-profiles/profiles5.vue"),
      },


      {
        path: "/profiles6",
        name: "profiles6",
        component: () => import("../views/data-profiles/profiles6.vue"),
      },
      {
        path: "/profiles7",
        name: "profiles7",
        component: () => import("../views/data-profiles/profiles7.vue"),
      },

      {
        path: "/profiles8",
        name: "profiles8",
        component: () => import("../views/data-profiles/profiles8.vue"),
      },


      {
        path: "/AlgorithmsOverview",
        name: "Algorithms-Overview",
        component: () => import("../views/data-algorithms/Algorithms-Overview.vue"),
      },
      {
        path: "/dataStrategy",
        name: "data-strategy",
        component: () => import("../views/data-strategy/index.vue"),
      },

      {
        path: "/data-metrics",
        name: "data-metrics",
        component: () => import("../views/data-algorithms/scenarios/data-metrics.vue"),
      },

      {
        path: "/long-time-forecast",
        name: "long-time-forecast",
        component: () => import("../views/data-algorithms/scenarios/long-time-forecast.vue"),
      },


      {
        path: "/month-based-forecast",
        name: "month-based-forecast",
        component: () => import("../views/data-algorithms/scenarios/month-based-forecast.vue"),
      },

      {
        path: "/1-Regression-Model",
        name: "1-Regression-Model",
        component: () => import("../views/data-algorithms/algo-desc/1-Regression-Model.vue"),
      },

      {
        path: "/2-Gray-Model",
        name: "2-Gray-Model",
        component: () => import("../views/data-algorithms/algo-desc/2-Gray-Model.vue"),
      },

      {
        path: "/3-EWMA-Model",
        name: "3-EWMA-Model",
        component: () => import("../views/data-algorithms/algo-desc/3-EWMA-Model.vue"),
      },

      {
        path: "/4-ARIMA-Model",
        name: "4-ARIMA-Model",
        component: () => import("../views/data-algorithms/algo-desc/4-ARIMA-Model.vue"),
      },


      {
        path: "/5-TimeSeries-Model",
        name: "5-TimeSeries-Model",
        component: () => import("../views/data-algorithms/algo-desc/5-TimeSeries-Model.vue"),
      },


      {
        path: "/test4Model",
        name: "test4Model",
        component: () => import("../views/data-algorithms/test4Model.vue"),
      },

      {
        path: "/ttt-Model",
        name: "ttt-Model",
        component: () => import("../views/data-algorithms/ttt-Model.vue"),
      },

      {
        path: "/chart-Model",
        name: "chart-Model",
        component: () => import("../views/data-algorithms/chart-Model.vue"),
      },


      {
        path: "/pinpai-graph",
        name: "pinpai-graph",
        component: () => import("../views/data-assets/pinpai-graph.vue"),
      },

      {
        path: "/company-graph",
        name: "company-graph",
        component: () => import("../views/data-assets/company-graph.vue"),
      },

    ]
  },


  {
    path: "/menu",
    name: "menu",
    component: () => import("../views/main/menu.vue"),
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/data-index/index.vue"),
      },
      {
        path: "/dashborad",
        name: "dashborad",
        component: () => import("../views/data-dashboard/index.vue"),
      },
      {
        path: "/dataReport1",
        name: "dataReport1",
        component: () => import("../views/data-reports/index.vue"),
      },
      {
        path: "/dataElt",
        name: "dataEtl",
        component: () => import("../views/data-etl/index.vue"),
      },
      {
        path: "/model-flow",
        name: "model-flow",
        component: () => import("../views/model-design/flow.vue"),
      },
      {
        path: "/model-design",
        name: "model-design",
        component: () => import("../views/model-design/index.vue"),
      },
      {
        path: "/dataResearch",
        name: "dataResearch",
        component: () => import("../views/data-research/index.vue"),
      },
    ]
  },

  {
    path: "/huishi",
    name: "huishi",
    component: () => import("../views/data-profiles/huishi.vue"),
  },


  {
    path: "/demo1",
    name: "demo1",
    component: () => import("../views/demo1.vue"),
  },

  {
    path: "/demo2",
    name: "demo2",
    component: () => import("../views/demo2.vue"),
  },

  {
    path: "/demo3",
    name: "demo3",
    component: () => import("../views/demo3.vue"),
  },


  {
    path: "/chart1",
    name: "chart1",
    component: () => import("../views/charts/chart1.vue"),
  },

  {
    path: "/nav",
    name: "nav",
    component: () => import("../views/nav.vue"),
  },
  {
    path: "/tab-svelte",
    name: "tab-sveltenav",
    component: () => import("../views/nav.vue"),
  },
  {
    path: "/tab-esbuild",
    name: "tab-esbuild",
    component: () => import("../views/nav.vue"),
  },
  {
    path: "/tab-next",
    name: "tab-next",
    component: () => import("../views/nav.vue"),
  },


]




const router = new VueRouter({
  routes: routes,
});



export default router;
