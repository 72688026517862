<template>
<div>
  <!-- <div class="open-chat" :class="(isOpen ? 'hide' : 'show')" @click="toggleChatOpen">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path :fill="iconColorProp" d="M899.3 171.4l-1.7 294.8s-4.9 159.7-176.2 159.7H481L373.9 736.3l176.2 1.6 242.1 250.3 8.3-256.9s189.4 13.2 189.4-154.8V327.8s8.2-100.3-90.6-156.4zM690.1 584.8c87.3 0 158.1-70.8 158.1-158.1V169.8c0-87.3-70.8-158.1-158.1-158.1h-522C80.8 11.7 10 82.5 10 169.8v256.9c0 87.3 70.8 158.1 158.1 158.1h13.3l1.6 233.9 261.2-233.9h245.9z"/></svg>  
    </svg>
    <span class="mtext">Click to Chat!</span>
  </div> -->
  <a href="#" class="click-to-chat--btn message-1" :class="(isOpen ? 'hide' : 'show')" @click="toggleChatOpen">
    <div class="click-text">
      <div class="message-1-text"><span>Need Help?</span><span>Click to Chat!</span></div>
      <div class="message-2-text"><span>Need help?</span><span>Visit the CRC!</span></div>
    </div>
    
    <div class="svg-container">
      <svg version="1.1" id="chat-icon" class="chat-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 32.5 29.4" style="enable-background:new 0 0 32.5 29.4;" xml:space="preserve">
      <path class="st0" d="M2.6,2.8v20h4.9v5.9l6.9-5.9H31v-20H2.6z M23.3,18.6H9.9c-1,0-1.8-0.5-1.8-1.2s0.8-1.2,1.8-1.2h13.5
        c1,0,1.8,0.5,1.8,1.2S24.3,18.6,23.3,18.6z M23.3,14.1H9.9c-1,0-1.8-0.5-1.8-1.2s0.8-1.2,1.8-1.2h13.5c1,0,1.8,0.5,1.8,1.2
        S24.3,14.1,23.3,14.1z M23.3,9.6H9.9c-1,0-1.8-0.5-1.8-1.2s0.8-1.2,1.8-1.2h13.5c1,0,1.8,0.5,1.8,1.2S24.3,9.6,23.3,9.6z"/>
      </svg>
      
      <svg version="1.1" id="crc-icon" class="crc-icon"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 32.5 29.4" enable-background="new 0 0 32.5 29.4" xml:space="preserve">
<path fill="#FFFFFF" d="M2.6,2.8v20h4.9v5.9l6.9-5.9H31v-20H2.6z M18.5,19.3c0,0.4-0.3,0.7-0.7,0.7h-1.8c-0.4,0-0.7-0.3-0.7-0.7
  v-1.1c0-0.4,0.3-0.7,0.7-0.7h1.8c0.4,0,0.7,0.3,0.7,0.7V19.3z M21.3,12.6c-0.5,0.6-1.1,1.1-1.8,1.5c-0.4,0.3-0.7,0.5-0.8,0.8
  c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3-0.3,0.4-0.5,0.4h-2.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.6,0.2-1.1,0.4-1.4c0.3-0.5,0.9-1,1.7-1.5
  c0.4-0.3,0.7-0.6,1-1c0.3-0.4,0.4-0.8,0.4-1.3c0-0.5-0.1-1-0.4-1.3c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.8,0.1-1.1,0.4
  c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.3-0.4,0.5-0.7,0.5l-2,0c-0.3,0-0.4-0.2-0.4-0.5v0v0l0,0c0.1-1.1,0.5-1.9,1.2-2.4
  c0.8-0.6,1.9-1,3.3-1c1.5,0,2.7,0.4,3.5,1.1c0.8,0.8,1.3,1.8,1.3,3C22,11.2,21.8,12,21.3,12.6z"/>
</svg>

    </div>
  </a>
  <div class="chat-container" :class="(isOpen ? 'show' : 'hide')">
    <iframe
        id="iframeContain"
        name="iframeContain"
        seamless
        scrolling="yes"
        width="650"
        height="500"
        frameborder="0"
        :src="iframeURL"
      >
        您当前的浏览器不支持页面上的功能，请升级您当前的浏览器版本或使用谷歌浏览器访问当前页面
     </iframe>
     <div class="close-chat" @click="toggleChatOpen" :style="{background: iconColorProp}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><g fill="none" stroke="#ffffff" stroke-width="10" stroke-miterlimit="10" stroke-linecap="round"><path d="M10 10l45 45M10 55l45-45"/></g></svg>
      </div>
    <!-- <div class="chat-window">
      <div class="close-chat" @click="toggleChatOpen" :style="{background: iconColorProp}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><g fill="none" stroke="#ffffff" stroke-width="10" stroke-miterlimit="10" stroke-linecap="round"><path d="M10 10l45 45M10 55l45-45"/></g></svg>
      </div>
      <div ref="chatArea" class="chat-area" :style="{background: messageBackgroundColorProp}">
        <p v-for="message in messageListProp" :key="message.id||message.body" class="message" :style="[message.author === 'you' ? {background: messageOutColorProp} : {background: messageInColorProp}]" :class="{'message-out': message.author === 'you', 'message-in': message.author !== 'you' }">
          {{ message.body }}
        </p>
      </div>
      <div class="chat-input">
        <form @submit.prevent="handleOutboundMessage()" class="chat-form">
          <input v-model="youMessage" type="text" :placeholder="messagePlaceholder" autofocus>
          <el-button icon="el-icon-s-promotion" native-type="submit" round></el-button>
          <button class="submit" type="submit">
            <i class="el-icon-s-promotion" :style="{color: iconColorProp, width: '30px'}"></i>
          </button>
        </form>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
// chat-ui: https://codepen.io/serdarasann/details/WLXdyX
//          https://codepen.io/erlandramdhani/details/vYxzoWq 
//          https://codepen.io/vinitparmar/pen/GRGYmwp
//          https://codepen.io/kp/details/ExyVQqG  
//          https://codepen.io/FlorisVC/pen/WNONrbO 
//          https://codepen.io/raul23/pen/RwYYJYd 
// chat-btn: https://codepen.io/thomasjwicker/details/zPmoQJ
//           https://codepen.io/kurtis-rogers/pen/rKmjRO 
//           https://codepen.io/eternalflame/details/jGboaO 
//           https://codepen.io/shivam-108/pen/abZZvQX   
//           https://codepen.io/arevuare/details/mpRPZb 

export default {
  name: 'Chat',
  inheritAttrs: false,
  props: {
    messagePlaceholder: {
      type: String,
      default: 'Type your message'
    },
    iconColorProp: {
      type: String,
      default: '#e6e6e6'
    },
    messageBackgroundColorProp: {
      type: String,
      default: '#ffffff'
    },
    messageOutColorProp: {
      type: String,
      default: '#3d7e9a'
    },
    messageInColorProp: {
      type: String,
      default: '#f1f0f0'
    },
    messageListProp: Array,
    initOpenProp: Boolean
  },

  data: () => {
    return {
      youMessage: '',
      isOpen: false,
      iframeURL:'http://localhost:7860/'
      // iframeURL:'https://c420-2409-8a00-16f-1080-901f-a4be-fd01-bf8d.ngrok-free.app/'
    }
  },

  methods: {
    handleOutboundMessage() {
      if (!this.youMessage) {
        return
      }
      this.$emit('onMessageWasSent', { body: this.youMessage, author: 'you' })
      this.youMessage = ''
      this.$nextTick(() => {
        this.messageScroll()
      })
    },
    toggleChatOpen() {
      this.isOpen = !this.isOpen
      this.$emit('onToggleOpen', this.isOpen)
    },
    messageScroll() {
      let messageDisplay = this.$refs.chatArea
      messageDisplay.scrollTop = messageDisplay.scrollHeight
    }
  },
  mounted() {
    this.isOpen = this.initOpenProp || false
    // if (this.messageListProp) {
    //   this.messageScroll()
    // }
  }
}
</script>

<style scoped lang="css">
  * {
    box-sizing: border-box;
  }
  .headline {
    text-align: center;
    font-weight: 100;
    color: white;
  }
  .chat-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    max-width: 650px;
    width: 100%;
    z-index: 10000;
    transform: scale(0);
    transform-origin: right bottom;
  }
  .chat-container.show {
    animation: scaleIn .15s ease-in-out 0s 1 normal forwards;
  }
  .chat-container.hide {
    display: none;
  }
  .chat-window {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .chat-area {
    border-radius: 3px 3px 0 0;
    height: 300px;
    padding: 1em 1em 0;
    position: relative;
    overflow: auto;
    width: 100%;
  }
  .message {
    width: 45%;
    border-radius: 10px;
    padding: .5em;
    font-size: .8em;
  }
  .message-out {
    color: #ffffff;
    margin-left: 50%;
  }
  .message-in {
    background: #f1f0f0;
    color: black;
  }
  .chat-inputs {
    display: flex;
    justify-content: space-between;
  }
  .chat-input input {
    border: none;
    font-size: .8em;
    outline: none;
    padding: 1.5em;
    width: 90%;
  }
  .chat-form {
    background: #ffffff;
    border-top: 1px solid #e9e9e9;
    border-radius: 0 0 3px 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }
  .submit {
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  .submit:focus {
    outline: none;
  }
  .submit-icon {
    width: 20px;
  }
  .close-chat {
    position: absolute;
    right: -40px;
    top: -40px;
    width: 35px;
    border-radius: 50%;
    height: 35px;
    background: #f7f7f7;
    cursor: pointer;
    transform: scale(0);
  }
  .chat-container.show .close-chat {
    animation: scaleIn .15s ease-in-out .3s 1 normal forwards;
  }
  .close-chat svg {
    position: relative;
    left: 7px;
    top: 6px;
    width: 20px;
  }
  .open-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:5px;
    border-radius: 30px 0 0 30px;
    border: 1px solid transparent;
    background-color: #fff;
    position: fixed;
    width: 70px;
    right: 0px;
    bottom: 25px;
    cursor: pointer;
    z-index: 900;
    transform: scale(0);
  }
  .open-chat .mtext{
    display: none;
  }
  .open-chat:hover .mtext{
    display:inline-block;
  }


  .open-chat.hide {
    display: none;
  }
  .open-chat.show {
    animation: scaleIn .15s ease-in-out .15s 1 normal forwards;
  }


  @keyframes scaleIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

.click-to-chat--btn {
	 position: fixed;
	 right: 20px;
	 bottom: 20px;
	 float: left;
	 width: 50px;
	 background-color: #0071a9;
	 text-align: center;
	 transition: all 200ms cubic-bezier(0.56, 1.56, 0.9, 0.96);
	 overflow: hidden;
	 border-radius: 50%;
}
.click-to-chat--btn.hide{
  display:none;
}
 .click-to-chat--btn:hover {
	 background-color: #0093dc;
	 border-radius: 20px;
	 animation: pulsecolor 1500ms infinite;
}
 .click-to-chat--btn:hover .click-text {
	 width: calc(100% - 50px);
	 display: block;
	 animation: fader 600ms forwards;
}
 .click-to-chat--btn:hover .svg-container {
	 width: 50px;
}
 .click-to-chat--btn:hover .svg-container svg {
	 fill: #109ed9;
	 animation: pulsecolor 1800ms cubic-bezier(0.56, 1.56, 0.9, 0.96) infinite;
}
 .click-to-chat--btn.message-1:hover {
	 width: 155px;
}
 .click-to-chat--btn.message-1 .click-text div.message-1-text {
	 display: block;
}
 .click-to-chat--btn.message-1 .svg-container .chat-icon, .click-to-chat--btn.message-1 .svg-container .crc-icon {
	 top: 12px;
	 left: 10px;
	 max-width: 28px;
}
 .click-to-chat--btn.message-1 .svg-container .chat-icon {
	 display: block;
}
 .click-to-chat--btn.message-1 .svg-container .crc-icon {
	 display: none;
}
 .click-to-chat--btn.message-2:hover {
	 width: 160px;
}
 .click-to-chat--btn.message-2 .click-text div.message-2-text {
	 display: block;
}
 .click-to-chat--btn.message-2 .svg-container .chat-icon, .click-to-chat--btn.message-2 .svg-container .crc-icon {
	 top: 12px;
	 left: 10px;
	 max-width: 28px;
}
 .click-to-chat--btn.message-2 .svg-container .chat-icon {
	 display: none;
}
 .click-to-chat--btn.message-2 .svg-container .crc-icon {
	 display: block;
}
 .click-to-chat--btn .click-text {
	 float: left;
	 height: 50px;
	 text-align: right;
	 display: none;
}
 .click-to-chat--btn .click-text .message-1-text, .click-to-chat--btn .click-text .message-2-text {
	 position: relative;
	 top: 4px;
	 margin-right: 2px;
	 font-family: Arial, serif;
	 font-size: 14px;
	 font-weight: 500;
	 line-height: 125%;
	 color: white;
	 display: none;
}
 .click-to-chat--btn .click-text .message-1-text span, .click-to-chat--btn .click-text .message-2-text span {
	 float: right;
	 clear: both;
}
 .click-to-chat--btn .click-text .message-1-text span:first-child, .click-to-chat--btn .click-text .message-2-text span:first-child {
	 font-family: Roboto, Arial, serif;
	 font-size: 11px;
	 font-weight: bold;
	 text-transform: uppercase;
}
 .click-to-chat--btn .click-text .message-1-text span:last-child, .click-to-chat--btn .click-text .message-2-text span:last-child {
	 font-family: Roboto, Arial, serif;
	 font-size: 14px;
	 font-weight: 500;
}
 .click-to-chat--btn .svg-container {
	 float: left;
	 width: 50px;
	 height: 50px;
}
 .click-to-chat--btn .svg-container .chat-icon {
	 display: none;
}
 .click-to-chat--btn .svg-container .crc-icon {
	 display: none;
}
 .click-to-chat--btn .svg-container svg {
	 position: relative;
	 fill: #fff;
}
 @keyframes fader {
	 0% {
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes pulsecolor {
	 0% {
		 fill: #fff;
	}
	 50% {
		 fill: #13baff;
	}
	 100% {
		 fill: #fff;
	}
}
</style>