import Vue from 'vue'
import App from './App.vue'

import router from "./router";
Vue.prototype.router = router;
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


// Bootstrap 样式的相关案例
import 'jquery/dist/jquery.min'
import 'bootstrap/dist/css/bootstrap.css'

// 引入font-awesome图标的样式
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import VueParticles from 'vue-particles'
Vue.use(VueParticles)



Vue.use(ElementUI, { size: 'small' })
Vue.use(Antd)







import $axios from "axios"
Vue.prototype.$axios = $axios



// 官网的说明
// https://github.com/hansonGong/mathjax-vue
// import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue'
// // Rewrite pageReady method, optimizing MathJax as required rendering and render to avoid them
// // ❗️❗️ For example '<span>$$Not a formula$$</span>' is not going to render, but in fact will be rendered into formula， As below is not the el
// function onMathJaxReady() {
//   // The parent node of need to be rendered into the formula node set
//   const el = document.getElementById('elementId')
//   // ❗️❗️ When there is no el will begin to render the default root node
//   renderByMathjax(el)
// }
// initMathJax({}, onMathJaxReady)
// // vue 2
// Vue.use(MathJax)







import utils from "./util/util"
Vue.prototype.utils = utils;

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
